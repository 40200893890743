import React from 'react'

import pdf from '../../../static/institucional/termos_e_condicoes_gerais_de_uso.pdf'
import { storeUrl } from '../../../store.config'
import { DownloadIcon } from '../../assets/DownloadIcon'
import useCmsInstitutional from '../../hooks/useCmsInstitutional'
import { NpmInstitutionalPage } from '../../local_modules'

import '../../styles/pages/termos.scss'

const Terms = () => {
  const { termsBanners } = useCmsInstitutional()

  return (
    <NpmInstitutionalPage
      title="Termos e Condições Gerais de Uso"
      banners={termsBanners}
    >
      <div className="terms-container">
        <p>
          A plataforma do website (“plataforma” e/ou site”) de propriedade do
          Dom Olívio, doravante denominado como “Dom Olívio”, mantida e
          disponibilizado pela <strong>IRMÃOS BOA LTDA.</strong>, pessoa
          jurídica de direito privado, inscrita no Cadastro Nacional de Pessoas
          Jurídicas (“CNPJ/MF”) sob o n. 50.948.371/0016-54, com sede na Rua do
          Retiro, 925 – Vila Virgínia, Jundiaí/SP, CEP: 13209-000, tem como
          destino o cadastro de seus clientes do Dom Olívio e Programa de
          Descontos e Fidelidade, para que possam realizar pesquisas de preços,
          usufruir dos serviços disponibilizados e dos ambientes oferecidos pela
          Dom Olívio, encontrando-se disponível de acesso através do seguinte
          website:{' '}
          <a href={storeUrl} target="_blank" rel="noreferrer">
            {storeUrl}
          </a>
        </p>
        <p>
          Considerando o compromisso assumido pelo Dom Olívio em busca da
          qualidade de seus serviços, da informação, da transparência e da
          segurança em suas operações realizadas através do presente Portal com
          seus clientes e consumidores, as atividades desenvolvidas seguem de
          maneira estrita o presente Termos de Uso e Aceite para o conhecimento
          das condições gerais de uso deste.
        </p>
        <p>
          <b>
            O conteúdo dos presentes Termos de Uso e Aceite deve ser analisado
            de maneira conjunta com o disposto em Aviso de Privacidade (Política
            Externa de Privacidade), disponível para acesso por meio do seguinte
            documento:
          </b>
        </p>
        <p>
          <a
            href={pdf}
            download="termos_e_condicoes_gerais_de_uso.pdf"
            type="application/pdf"
          >
            <DownloadIcon />
            Baixar Documento Completo
          </a>
        </p>
      </div>
    </NpmInstitutionalPage>
  )
}

export default Terms
